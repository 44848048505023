<template>
  <div class="content-box-padding">
    <div class="line-text">
      研究院紧紧围绕南京大学“一流大气科学学科”发展战略，南京市“两落地、一融合”决策部署，以“融业务培养与科技创新能力培养为一体”的模式为人才培养工作的具体目标，促进人才培养供给侧和产业需求侧结构要素全方位融合，培养大批高素质创新人才和技术技能人才，建设以重大科技研发为基础、以成果产业落地为导向的新型研发机构，增强产业核心竞争力，汇聚发展新动能提供有力支撑。<br />
      研究院内部不定期开展相关业务培训，与合作单位进行学术、业务交流。
    </div>
    <img
      src="~assets\image\recruit\人才1.png"
      alt="人才1"
      style="margin: 40px auto 10px auto; width: 70%"
    />
    <div class="img-title">学术交流会</div>

    <img
      src="~assets\image\recruit\人才2.png"
      alt="人才2"
      style="margin: 40px auto 10px auto; width: 70%"
    />
    <div class="img-title">业务培训会</div>

    <img
      src="~assets\image\recruit\人才3.png"
      alt="人才3"
      style="margin: 40px auto 10px auto"
    />
    <div class="img-title">在专家工作室开展专题数据分析讨论会</div>

    <img
      src="~assets\image\recruit\人才4.png"
      alt="人才4"
      style="margin: 40px auto 10px auto; width: 90%"
    />
    <div class="img-title">技术研讨会</div>
  </div>
</template>
